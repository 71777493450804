<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Organisatie instellingen</h1>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/edit">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Gegevens</h3>
        <p class="text-muted mb-0">Bewerk de gegevens van je organisatie.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/profile">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Profiel</h3>
        <p class="text-muted mb-0">Bewerk de gegevens van je Tixgo profiel.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/payments">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Betalingen</h3>
        <p class="text-muted mb-0">Bewerk de betalingspartner van je organisatie.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/locations">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Locaties</h3>
        <p class="text-muted mb-0">Bewerk de locaties van je organisatie.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/webshop">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Webshop</h3>
        <p class="text-muted mb-0">Instellingen voor styling en het linken naar je webshop.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary"  to="/manage/organisation/links">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Koppelingen</h3>
        <p class="text-muted mb-0">Koppelingen met bijvoorbeeld Tag Manager en Facebook.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary" to="/manage/organisation/team">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Team</h3>
        <p class="text-muted mb-0">Beheer gebruikers die toegang hebben tot het dashboard.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary"  to="/manage/organisation/invoices">Bekijken</b-button>
        <h3 class="fs-5 mb-0">Facturen</h3>
        <p class="text-muted mb-0">Bekijk je facturen.</p>
      </b-card>

      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary"  to="/manage/organisation/exports">Bekijken</b-button>
        <h3 class="fs-5 mb-0">Exporteren</h3>
        <p class="text-muted mb-0">Exporteer gegevens van het systeem voor externe toepassingen.</p>
      </b-card>

      <!--
      <b-card class="mb-3">
        <b-button class="float-right mt-2 ml-2" variant="primary"  to="/manage/organisation/balance">Bewerken</b-button>
        <h3 class="fs-5 mb-0">Saldo</h3>
        <p class="text-muted mb-0">Bekijk je huidige saldo.</p>
      </b-card>
      -->

    </b-container>
  </div>
</template>

<script>
    export default {
      metaInfo: {
        title: 'Organisatie',
      },
      components: {
      },
      data() {
        return {
          events: []
        }
      },
      computed: {},
      methods: {},
      created() {},
    }
</script>